import { Cancel, CheckCircle } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import get from "lodash/get";
import moment from "moment";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useMutation, useQuery } from "react-query";
import MainLayout from "../components/MainLayout";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";
import useNotification from "../hooks/useNotification";
import approvalService from "../services/approval";
import queryDefault from "../utils/queryDefault";

export default function Approval() {
  const [pageSize, setPageSize] = useState<number>(5);
  const notification = useNotification();
  const listApproval = useQuery(
    ["approval"],
    () => {
      return approvalService.viewAllApproval();
    },
    {
      ...queryDefault,
    }
  );
  const pendingApproval = useQuery(
    ["pending"],
    () => {
      return approvalService.viewAllApprovalPending();
    },
    {
      ...queryDefault,
    }
  );

  const updateApproval = useMutation(
    "updateStatus",
    approvalService.updateApprovalStatus,
    {
      onSuccess: () => {
        notification.onOpen({
          message: "Success Update Status",
          type: "success",
          position: "top",
        });
        pendingApproval.refetch();
        listApproval.refetch();
      },
      onError(error: string, variables, context) {
        notification.onOpen({
          message: error,
          type: "error",
          position: "top",
        });
      },
    }
  );

  const handleSubmit = (result: boolean, id: number) => {
    console.log(result, id);
    updateApproval.mutate({
      id,
      status: result,
    });
  };

  return (
    <MainLayout>
      <Helmet>
        <title>APPROVAL</title>
      </Helmet>

      <Typography variant="h1">Approval Pending</Typography>

      <ProtectedPermissionPage acceptPermissions={[1, 2]}>
        <Box sx={{ mt: 2 }}>
          <DataGrid
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            autoHeight
            loading={pendingApproval.isLoading}
            getRowId={(record) => get(record, "id")}
            rows={get(pendingApproval, "data.data", [])}
            paginationMode="client"
            components={{ Toolbar: GridToolbar, NoRowsOverlay: noRowsOverlay }}
            disableSelectionOnClick
            rowsPerPageOptions={[5, 10, 15]}
            columns={[
              {
                field: "name",
                headerName: "Branch Name",
                flex: 1,
                sortable: true,
                filterable: true,
                minWidth: 150,
              },
              {
                field: "reportdate",
                headerName: "Report Date",
                flex: 1,
                sortable: true,
                filterable: true,
                minWidth: 150,
                renderCell: (params) => {
                  if (!params.value) {
                    return "-";
                  }

                  return moment(params.value).format("DD/MM/YYYY");
                },
              },
              {
                field: "requestedby",
                headerName: "Requested By",
                flex: 1,
                sortable: true,
                filterable: true,
                minWidth: 150,
              },
              {
                field: "requestedtime",
                headerName: "Request Time",
                flex: 1,
                sortable: true,
                filterable: true,
                minWidth: 150,
                renderCell: (params) => {
                  if (!params.value) {
                    return "-";
                  }

                  return moment(params.value).format("DD/MM/YYYY HH:mm:ss");
                },
              },
              {
                field: "quantity",
                headerName: "Quantity",
                flex: 1,
                sortable: true,
                filterable: true,
                minWidth: 150,
              },
              {
                field: "newquantity",
                headerName: "New Quantity",
                flex: 1,
                sortable: true,
                filterable: true,
                minWidth: 150,
              },
              {
                field: "price",
                headerName: "Price",
                flex: 1,
                sortable: true,
                filterable: true,
                minWidth: 150,
              },
              {
                field: "reportid",
                headerName: "Category",
                flex: 1,
                sortable: false,
                filterable: false,
                minWidth: 150,
                renderCell: (params) => {
                  if (!params.value) {
                    return "NEW";
                  }
                  return "EDIT";
                },
              },
              {
                field: "actions",
                headerName: "Action",
                sortable: false,
                filterable: false,
                width: 200,
                align: "center",
                renderCell: (params) => {
                  return (
                    <Stack direction="row" spacing={1}>
                      <Button
                        variant="outlined"
                        color="success"
                        onClick={() => handleSubmit(true, params.row.id)}
                      >
                        Approve
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleSubmit(false, params.row.id)}
                      >
                        Reject
                      </Button>
                    </Stack>
                  );
                },
              },
            ]}
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
              },
            }}
          />
        </Box>
      </ProtectedPermissionPage>
      <Typography variant="h1" marginTop="10px">
        Approval History
      </Typography>

      <ProtectedPermissionPage acceptPermissions={[1, 2]}>
        <Box sx={{ mt: 2 }}>
          <DataGrid
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            autoHeight
            loading={listApproval.isLoading}
            getRowId={(record) => get(record, "id")}
            rows={get(listApproval, "data.data", [])}
            paginationMode="client"
            components={{ Toolbar: GridToolbar, NoRowsOverlay: noRowsOverlay }}
            disableSelectionOnClick
            rowsPerPageOptions={[5, 10, 15]}
            columns={[
              {
                field: "name",
                headerName: "Branch Name",
                flex: 1,
                sortable: true,
                filterable: true,
                minWidth: 150,
              },
              {
                field: "reportdate",
                headerName: "Report Date",
                flex: 1,
                sortable: true,
                filterable: true,
                minWidth: 150,
                renderCell: (params) => {
                  if (!params.value) {
                    return "-";
                  }

                  return moment(params.value).format("DD/MM/YYYY");
                },
              },
              {
                field: "requestedby",
                headerName: "Requested By",
                flex: 1,
                sortable: true,
                filterable: true,
                minWidth: 150,
              },
              {
                field: "requestedtime",
                headerName: "Request Time",
                flex: 1,
                sortable: true,
                filterable: true,
                minWidth: 150,
                renderCell: (params) => {
                  if (!params.value) {
                    return "-";
                  }

                  return moment(params.value).format("DD/MM/YYYY HH:mm:ss");
                },
              },
              {
                field: "approvedtime",
                headerName: "Processed Time",
                flex: 1,
                sortable: true,
                filterable: true,
                minWidth: 150,
                renderCell: (params) => {
                  if (!params.value) {
                    return "-";
                  }

                  return moment(params.value).format("DD/MM/YYYY HH:mm:ss");
                },
              },
              {
                field: "quantity",
                headerName: "Quantity",
                flex: 1,
                sortable: true,
                filterable: true,
                minWidth: 100,
              },
              {
                field: "newquantity",
                headerName: "New Quantity",
                flex: 1,
                sortable: true,
                filterable: true,
                minWidth: 120,
              },
              {
                field: "price",
                headerName: "Price",
                flex: 1,
                sortable: true,
                filterable: true,
                minWidth: 120,
              },
              {
                field: "reportid",
                headerName: "Category",
                flex: 1,
                sortable: false,
                filterable: false,
                minWidth: 100,
                renderCell: (params) => {
                  if (!params.value) {
                    return "NEW";
                  }
                  return "EDIT";
                },
              },
              {
                field: "isapproved",
                headerName: "Approved",
                flex: 1,
                sortable: true,
                filterable: true,
                minWidth: 100,
                renderCell(params) {
                  if (params.value) return <CheckCircle color="success" />;
                  return <Cancel color="error" />;
                },
              },
            ]}
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
              },
            }}
          />
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
function noRowsOverlay() {
  return (
    <Box sx={{ pt: 5 }}>
      <Typography variant="body1" textAlign="center" fontWeight="600">
        Sorry, data is not found
      </Typography>
    </Box>
  );
}
