import axios from "axios";
import fileDownload from 'js-file-download';
import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";

const getReport = async (params: any) => {
  const url = endpoints.REPORT;

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params,
  });

  return response;
};

const getReportRange = async (params: any) => {
  const url = endpoints.REPORT + "/range";

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params,
  });

  return response;
};

const getDetailReport = async (id: any) => {
  if (parseInt(id)) {
    const url = endpoints.DETAIL_REPORT(id);
    const { data: response } = await axios.get(url, {
      headers: {
        Authorization: accessToken,
      },
    });
    return response;
  }
};

const createReport = async (params: any) => {
  const url = endpoints.REPORT;
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const updateReport = async (params: any) => {
  const url = endpoints.DETAIL_REPORT(params?.data.id);
  const { data: response } = await axios.patch(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const downloadReport = async (params: any) => {
  const url = endpoints.REPORT_DOWNLOAD;
  const parameters = { 'branchid': params.branchid, 'groupid': params.group_id, 'month': params.month, 'year': params.year };
  await axios.post(url, parameters, {
    headers: {
      Authorization: accessToken,
      ContentType: 'application/pdf',
    },
    responseType: 'arraybuffer',
  }).then((res) => {
    const contentDisposition = res.headers['content-disposition'];
    const filename = contentDisposition?.split('filename=')[1] || `report-${params.month}-${params.year}.pdf`;
    var blob = new Blob([res.data], { type: 'application/pdf' });
    fileDownload(blob, filename);
  });
};

const splitRevenuePayroll = async (params: any) => {
  const url = endpoints.REPORT + "/payroll";
  const parameters = { 'month': params.month, 'year': params.year };
  await axios
    .get(url, {
      headers: {
        Authorization: accessToken,
        ContentType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      params: parameters,
      responseType: "arraybuffer",
    })
    .then((res) => {
      var blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fileDownload(blob, `splitRevenuePayroll_${params.month}_${params.year}.xlsx`);
    });
};

const sendEmail = async (params: any) => {
  const url = endpoints.BASE_API + "report/sendEmail";
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const getAllReport = async (params: any) => {
  const date_from = params.date_from;
  const date_to = params.date_to;
  const url = endpoints.REPORT + "/all";
  await axios
    .get(url, {
      headers: {
        Authorization: accessToken,
        ContentType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      params: params,
      responseType: "arraybuffer",
    })
    .then((res) => {
      var blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fileDownload(blob, `reports_${date_from}_${date_to}.xlsx`);
    });
};

const viewAllReport = async (params: any) => {
  const url = endpoints.REPORT + "/viewall";
  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params,
  });

  return response;
};

const uploadBulk = async (params: any) => {
  const url = endpoints.REPORT_UPLOAD;
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const uploadBulkXendit = async (params: any) => {
  const url = endpoints.REPORT_UPLOAD_XENDIT;
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const uploadBulkMidtrans = async (params: any) => {
  const url = endpoints.REPORT_UPLOAD_MIDTRANS;
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const resetReport = async (params: any) => {
  const url = endpoints.REPORT;
  const { data: response } = await axios.patch(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const report = {
  getReport,
  getReportRange,
  getDetailReport,
  createReport,
  updateReport,
  downloadReport,
  sendEmail,
  getAllReport,
  viewAllReport,
  uploadBulk,
  uploadBulkXendit,
  uploadBulkMidtrans,
  resetReport,
  splitRevenuePayroll,
};

export default report;
