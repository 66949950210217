import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { Paper, Typography, Collapse, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface BranchData {
  group: string;
  name: string;
  place: string;
  quantity: string;
  omzet: number;
  subtotal: number;
  [key: string]: any; // Allows dynamic date keys (e.g., "_30sep")
}

interface BarChartProps {
  data: BranchData[];
}

const BarChartComponent: React.FC<BarChartProps> = ({ data }) => {
  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});

  const handleToggle = (branch: string) => {
    setExpanded((prev) => ({ ...prev, [branch]: !prev[branch] }));
  };

  return (
    <div>
      {data.map((branch) => {
        // Extract date-based data into a chart-friendly format
        const dateData = Object.keys(branch)
          .filter((key) => key.startsWith("_")) // Only keys with an underscore are dates
          .map((dateKey) => {
            const total = Number(branch[dateKey]);
            return {
              date: dateKey.replace("_", ""), // Remove the leading underscore for display
              total: total,
              quantity: branch.quantity,
              omzet: branch.omzet,
              subtotal: branch.subtotal,
              fillColor: "#8884d8", // Custom color if value is -1
            };
          });

        return (
          <Paper
            key={branch.name}
            style={{ marginBottom: "20px", padding: "20px" }}
          >
            <Typography
              variant="h6"
              style={{ display: "flex", alignItems: "center" }}
            >
              <IconButton onClick={() => handleToggle(branch.name)}>
                <ExpandMoreIcon
                  style={{
                    transform: expanded[branch.name]
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                    transition: "transform 0.3s",
                  }}
                />
              </IconButton>
              {branch.name}
            </Typography>
            <Collapse in={expanded[branch.name]} timeout="auto" unmountOnExit>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={dateData}>
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip
                    formatter={(value: number) =>
                      new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      }).format(value)
                    }
                  />
                  <Legend />
                  <Bar dataKey="total" animationDuration={0}>
                    {dateData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.fillColor} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
              <Typography variant="body2">
                Quantity: {branch.quantity} | Omzet:{" "}
                {new Intl.NumberFormat("id-ID", {
                  style: "currency",
                  currency: "IDR",
                }).format(branch.omzet)}{" "}
                | Subtotal:{" "}
                {new Intl.NumberFormat("id-ID", {
                  style: "currency",
                  currency: "IDR",
                }).format(branch.subtotal)}
              </Typography>
            </Collapse>
          </Paper>
        );
      })}
    </div>
  );
};

export default BarChartComponent;
