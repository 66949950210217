import axios from "axios";
import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";
import fileDownload from "js-file-download";

const listSales = async (params: any) => {
  const url = endpoints.SALES;

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params
  });

  return response;
};

const getAttendees = async (params: any) => {
  const url = endpoints.SALES + "/attendance";

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params
  });

  return response;
};

const createSales = async (params: any) => {
  const url = endpoints.SALES;
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  })
  return response;
};

const getSales = async (id: any) => {
  const url = endpoints.SALES + `/${id}`;
  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
  })
  return response;
};

const updateSales = async (params: any) => {
  const url = endpoints.SALES + `/${params.id}`;
  const { data: response } = await axios.put(url, params, {
    headers: {
      Authorization: accessToken,
    },
  })
  return response;
};

const monthlySummary = async (params: any) => {
  const url = endpoints.SALES + "/monthly";

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params
  });

  return response;
};

const salesPerformance = async (params: any) => {
  const url = endpoints.SALES + "/sales-performance";

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params
  });

  return response;
};

const deleteSales = async (id: number) => {
  const url = endpoints.DETAIL_SALES(id);

  const { data: response } = await axios.delete(url, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const downloadMonthlyReport = async (params: any) => {
  const url = endpoints.SALES + "/export-monthly";
  await axios
    .get(url, {
      headers: {
        Authorization: accessToken,
        ContentType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      params,
      responseType: "arraybuffer",
    })
    .then((res) => {
      var blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fileDownload(blob, `monthly_report_${params.month}_${params.year}.xlsx`);
    });
};

const downloadKertasReport = async (params: any) => {
  const url = endpoints.SALES + "/export-kertas";
  await axios
    .get(url, {
      headers: {
        Authorization: accessToken,
        ContentType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      params,
      responseType: "arraybuffer",
    })
    .then((res) => {
      var blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fileDownload(blob, `kertas_report_${params.month}_${params.year}.xlsx`);
    });
};

const downloadKertasReportLast = async (params: any) => {
  const url = endpoints.SALES + "/export-kertas-last";
  await axios
    .get(url, {
      headers: {
        Authorization: accessToken,
        ContentType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      params,
      responseType: "arraybuffer",
    })
    .then((res) => {
      var blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fileDownload(blob, `kertas_report_last_${params.month}_${params.year}.xlsx`);
    });
};

const downloadMonthlySales = async (params: any) => {
  const url = endpoints.SALES + "/monthly-sales";
  await axios
    .get(url, {
      headers: {
        Authorization: accessToken,
        ContentType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      params,
      responseType: "arraybuffer",
    })
    .then((res) => {
      var blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fileDownload(blob, `monthly_sales_${params.month}_${params.year}.xlsx`);
    });
};

const downloadMonthlySalesRange = async (params: any) => {
  const url = endpoints.SALES + "/monthly-sales-range";
  await axios
    .get(url, {
      headers: {
        Authorization: accessToken,
        ContentType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      params,
      responseType: "arraybuffer",
    })
    .then((res) => {
      var blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fileDownload(blob, `monthly_sales_${params.startDate}_${params.endDate}.xlsx`);
    });
};

const downloadMonthlySalesGroup = async (params: any) => {
  const url = endpoints.SALES + "/monthly-sales-group";
  await axios
    .get(url, {
      headers: {
        Authorization: accessToken,
        ContentType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      params,
      responseType: "arraybuffer",
    })
    .then((res) => {
      var blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fileDownload(blob, `monthly_sales_group_${params.month}_${params.year}.xlsx`);
    });
};

const downloadMonthlySalesGroupRange = async (params: any) => {
  const url = endpoints.SALES + "/monthly-sales-group-range";
  await axios
    .get(url, {
      headers: {
        Authorization: accessToken,
        ContentType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      params,
      responseType: "arraybuffer",
    })
    .then((res) => {
      var blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fileDownload(blob, `monthly_sales_group_${params.startDate}_${params.endDate}.xlsx`);
    });
};

const downloadSalesPerformance = async (params: any) => {
  const url = endpoints.SALES + "/export-sales-performance";
  await axios
    .get(url, {
      headers: {
        Authorization: accessToken,
        ContentType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      params,
      responseType: "arraybuffer",
    })
    .then((res) => {
      var blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fileDownload(blob, `sales_performance_${params.month}_${params.year}.xlsx`);
    });
};

const services = {
  listSales,
  createSales,
  monthlySummary,
  getAttendees,
  updateSales,
  getSales,
  deleteSales,
  downloadMonthlyReport,
  downloadKertasReport,
  downloadMonthlySales,
  downloadMonthlySalesRange,
  downloadMonthlySalesGroup,
  downloadMonthlySalesGroupRange,
  downloadKertasReportLast,
  downloadSalesPerformance,
  salesPerformance
};

export default services;
