const sideMenu = [
  {
    id: "branch",
    label: "Branch",
    href: "/app/branch",
    permissions: [1,2]
  },
  {
    id: "branch_group",
    label: "Branch Group",
    href: "/app/branch-group",
    permissions: [1,2]
  },
  {
    id: "approval_list",
    label: "Approval List",
    href: "/app/approval",
    permissions: [1]
  },
  {
    id: "userlist",
    label: "User Management",
    href: "/app/users",
    permissions: [1,2]
  },
  {
    id: "report",
    label: "Report",
    href: "/app/report",
    permissions: [1,2,3]
  },
  {
    id: "reportrange",
    label: "Report (Range)",
    href: "/app/range-report",
    permissions: [1,2,3]
  },
  {
    id: "division",
    label: "Division",
    href: "/app/division",
    permissions: [4,5]
  },
  {
    id: "employee",
    label: "Employee",
    href: "/app/employee",
    permissions: [4,5]
  },
  {
    id: "employee_group",
    label: "Employee Group",
    href: "/app/employee-group",
    permissions: [4,5]
  },
  {
    id: "checkin",
    label: "Checkin / Checkout",
    href: "/app/checkin",
    permissions: [6]
  },
  {
    id: "dashboard",
    label: "Dashboard",
    href: "/app/attendance/dashboard",
    permissions: [6]
  },
  {
    id: "attendance",
    label: "Attendance",
    href: "/app/attendance",
    permissions: [4,5]
  },
  {
    id: "sales",
    label: "Sales",
    href: "/app/sales",
    permissions: [1,8]
  },
  {
    id: "salesdashboard",
    label: "Sales Dashboard",
    href: "/app/dashboard",
    permissions: [1,8,9]
  },
  {
    id: "salesperformance",
    label: "Sales Performance",
    href: "/app/sales-performance",
    permissions: [1,8]
  },
];

export default sideMenu;
