import {
  Box,
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Stack
} from "@mui/material";
import get from "lodash/get";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import Breadcrumb from "../../components/Breadcrumb";
import MainLayout from "../../components/MainLayout";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import service from "../../services/sales";
import queryDefault from "../../utils/queryDefault";
import ReportTable from "../report/ReportTable";

export default function MonthlySales() {
  const defaultValues = {
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  }
  const form = useForm({ defaultValues });

  const [options, setOptions] = useState<any>(defaultValues);
  const [pageSize, setPageSize] = useState<number>(10);
  const listSalesPerformance = useQuery(
    ["listSalesPerformance", options],
    ({ queryKey }) => {
      return service.salesPerformance({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  let thisYear = (new Date()).getFullYear();
  let allYears: number[] = [];
  for (let x = 0; x <= 20; x++) {
    allYears.push(thisYear - x);
  }

  const rupiah = (number: any) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
    }).format(number)
  };

  function capitalizeFLetter(input: string) {
    return input[0].toUpperCase() + input.slice(1);
  }

  function replaceAll(str: string, find: string, replace: string) {
    return str.replace(new RegExp(find, 'g'), replace);
  }

  return (
    <MainLayout>
      <Breadcrumb
        label={"Sales Performance"}
        breadcrumbs={[
          { label: "Sales", href: "/app/sales" },
        ]}
      />

      <ProtectedPermissionPage acceptPermissions={[1, 8]}>
        <Box sx={{ mt: 2 }}>
          <Stack direction="row" spacing={1}>
            <FormControl margin="normal">
              <FormLabel>Month</FormLabel>
              <Controller
                name="month"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <Select
                    id="month"
                    value={form.getValues().month}
                    onChange={(event) => {
                      form.setValue("month", Number(event.target.value));
                      setOptions((prev: any) => ({
                        ...prev,
                        month: Number(event.target.value)
                      }));
                      listSalesPerformance.refetch();
                    }}
                    defaultValue={form.getValues().month}
                  >
                    <MenuItem value={0}>Select Month</MenuItem>
                    <MenuItem value={1}>January</MenuItem>
                    <MenuItem value={2}>February</MenuItem>
                    <MenuItem value={3}>March</MenuItem>
                    <MenuItem value={4}>April</MenuItem>
                    <MenuItem value={5}>May</MenuItem>
                    <MenuItem value={6}>June</MenuItem>
                    <MenuItem value={7}>July</MenuItem>
                    <MenuItem value={8}>August</MenuItem>
                    <MenuItem value={9}>September</MenuItem>
                    <MenuItem value={10}>October</MenuItem>
                    <MenuItem value={11}>November</MenuItem>
                    <MenuItem value={12}>December</MenuItem>
                  </Select>
                )}
              />
            </FormControl>

            <FormControl margin="normal">
              <FormLabel>Year</FormLabel>
              <Controller
                name="year"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <Select
                    id="year"
                    value={form.getValues().year}
                    onChange={(event) => {
                      form.setValue("year", Number(event.target.value));
                      setOptions((prev: any) => ({
                        ...prev,
                        year: Number(event.target.value)
                      }));
                      listSalesPerformance.refetch();
                    }}
                    defaultValue={form.getValues().year}
                  >
                    <MenuItem key={0} value={0}>
                      Select Year
                    </MenuItem>
                    {allYears.map((x) => (
                      <MenuItem key={x} value={x}>
                        {x}
                      </MenuItem>
                    )
                    )}
                  </Select>
                )}
              />
            </FormControl>
          </Stack>
        </Box>

        <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
          <Button
            variant="contained"
            onClick={() => service.downloadSalesPerformance(options)}>
            Download
          </Button>
        </Stack>

        <ReportTable
          head={listSalesPerformance.isLoading ? [<th className="greenBackground" key="name">Name</th>]
            : Object.keys(get(listSalesPerformance, "data.data", ["name"])[0])
              .map((data: any) => {
                return <th className="greenBackground" key={data}>
                  {capitalizeFLetter(replaceAll(data, "_", " "))}
                </th>
              })}
          body={listSalesPerformance.isLoading ? <tr><td>Loading, please wait</td></tr> : get(listSalesPerformance, "data.data", []).map((data: any) => (
            <tr>
              {
                Object.keys(get(listSalesPerformance, "data.data", ["name"])[0]).map((v: any) => {
                  if (v === "omzet" || v === "subtotal") {
                    return <td>{rupiah(data[v])}</td>
                  } else {
                    return <td>{data[v]}</td>
                  }
                })
              }
            </tr>
          )
          )}>
        </ReportTable>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
