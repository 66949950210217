import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import CookieJs from "js-cookie";
import { get } from "lodash";
import Moment from "moment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Breadcrumb from "../../components/Breadcrumb";
import MainLayout from "../../components/MainLayout";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import useNotification from "../../hooks/useNotification";
import branch from "../../services/branch";
import report from "../../services/report";
import queryDefault from "../../utils/queryDefault";

export default function ReportEditPage() {
  const { id } = useParams();
  const notification = useNotification();
  const navigate = useNavigate();
  const user = JSON.parse(CookieJs.get("USER") || "");
  const schema = yup.object().shape({
    branchid: yup.string().required("branch name is a required field"),
  });
  const [options] = useState<any>({
    keyword: "",
  });
  const [value, setValue] = useState<Dayjs | null>(dayjs(new Date()));
  const currentReport = useQuery("view-report", () =>
    report.getDetailReport(id)
  );
  const form = useForm({
    defaultValues: {
      id: 0,
      branchid: 0,
      name: "",
      date: Moment(new Date()).format("YYYY-MM-DD"),
      quantity: 0,
      username: user.username,
      price: 0,
    },
    resolver: yupResolver(schema),
  });

  const getBranches = useQuery(
    ["branch", options],
    ({ queryKey }) => {
      return branch.getAllBranch({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  const createReport = useMutation("create-report", report.createReport, {
    onSuccess: (response) => {
      form.setValue("quantity", 0);
      notification.onOpen({
        message:
          response.message === ""
            ? "Report was successfully created!"
            : response.message,
        type: "success",
        position: "top",
      });
    },
    onError: (error: any) => {
      notification.onOpen({
        message:
          error?.response?.data?.message === undefined
            ? "Something went wrong"
            : error?.response?.data?.message,
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const updateReport = useMutation("update-report", report.updateReport, {
    onSuccess: (response) => {
      navigate("/app/report/");

      notification.onOpen({
        message:
          response.message === ""
            ? "Report was successfully updated!"
            : response.message,
        type: "success",
        position: "top",
      });
    },
    onError: (error: any) => {
      notification.onOpen({
        message:
          error?.response?.data?.message === undefined
            ? "Something went wrong"
            : error?.response?.data?.message,
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const onSubmit = (values: any) => {
    if (id) {
      updateReport.mutate({
        data: {
          ...values,
          id: id,
        },
      });
    } else {
      createReport.mutate({
        data: values,
      });
    }
  };

  useEffect(() => {
    if (id) {
      form.setValue("id", get(currentReport, "data.data.id"));
      form.setValue("branchid", get(currentReport, "data.data.branchid"));
      form.setValue("name", get(currentReport, "data.data.name"));
      form.setValue("date", get(currentReport, "data.data.date"));
      form.setValue("quantity", get(currentReport, "data.data.quantity"));
      form.setValue(
        "price",
        get(currentReport, "data.data.price") === 0
          ? get(currentReport, "data.data.priceperunit")
          : get(currentReport, "data.data.price")
      );
      if (
        Moment(value?.format("YYYY-MM-DD")).format("YYYY-MM-DD") !==
        Moment(form.getValues("date")).format("YYYY-MM-DD")
      ) {
        setValue(dayjs(get(currentReport, "data.data.date")));
      }
    }
  }, [currentReport, form, id]);

  useEffect(() => {
    form.setValue(
      "date",
      Moment(value?.format("YYYY-MM-DD")).format("YYYY-MM-DD")
    );
  }, [value]);

  const [filter, setFilter] = useState("");
  const branches = get(getBranches, "data.data", []);

  return (
    <MainLayout>
      <Breadcrumb
        label={id ? "Edit Report" : "Create Report"}
        breadcrumbs={[
          { label: "Report", href: "/app/report" },
          {
            label: id ? "Edit Report" : "Create Report",
            href: "/app/report/create-report",
          },
        ]}
      />

      <ProtectedPermissionPage acceptPermissions={[1, 2, 3]}>
        {!currentReport.isLoading ? (
          <Box sx={{ mt: 2 }}>
            <Grid container>
              <Grid item lg={4} xs={12}>
                <FormControl fullWidth margin="normal">
                  <FormLabel required>Branch</FormLabel>
                  <Controller
                    name="branchid"
                    control={form.control}
                    render={({ field }) => (
                      <Autocomplete
                        options={branches}
                        getOptionLabel={(option:any) => option.name}
                        onChange={(event, newValue) => {
                          form.setValue("branchid", newValue ? newValue.id : 0);
                        }}
                        filterOptions={(options, { inputValue }) =>
                          options.filter((option) =>
                            option.name
                              .toLowerCase()
                              .includes(inputValue.toLowerCase())
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Branch"
                            variant="outlined"
                            value={filter}
                            onChange={(e:any) => {
                              setFilter(e.target.value);
                              if(params.inputProps.onChange) {
                                params.inputProps.onChange(e); // Ensure the input value is managed correctly
                              }
                            }}
                          />
                        )}
                        disabled={form.getValues().id > 0}
                        noOptionsText="No branches available"
                        value={branches.find((branch: any) => branch.id === form.getValues().branchid) || null}
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel>Date</FormLabel>
                  <Controller
                    name="date"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          inputFormat="DD/MM/YYYY"
                          views={["day", "month", "year"]}
                          value={value}
                          onChange={(newValue) => {
                            setValue(newValue);
                          }}
                          disabled={form.getValues().id > 0}
                          renderInput={(props) => <TextField {...props} />}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel>Quantity</FormLabel>
                  <Controller
                    name="quantity"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        type="number"
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel>Price</FormLabel>
                  <Controller
                    name="price"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        type="number"
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <></>
        )}
        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <LoadingButton
              variant="contained"
              onClick={form.handleSubmit(onSubmit)}
              loading={createReport.isLoading}
            >
              SAVE
            </LoadingButton>

            <Button variant="outlined" onClick={() => navigate("/app/report/")}>
              CANCEL
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
