import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import CookieJs from "js-cookie";
import get from "lodash/get";
import { default as Moment, default as moment } from 'moment';
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import MainLayout from "../../components/MainLayout";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import branch from "../../services/branch";
import group from "../../services/group";
import report from "../../services/report";
import sales from "../../services/sales";
import queryDefault from "../../utils/queryDefault";

const CustomNoRowOverlay = () => (
  <Box sx={{ pt: 5 }}>
    <Typography variant="body1" textAlign="center" fontWeight="600">
      Sorry, data is not found
    </Typography>
  </Box>
);

export default function ReportRange() {
  const [valueFrom, setValueFrom] = useState<Dayjs | null>(dayjs(new Date()));
  const [valueTo, setValueTo] = useState<Dayjs | null>(dayjs(new Date()));
  const reportCache =
    CookieJs.get("REPORT_RANGE_PAGE") === undefined
      ? {
        branchid: 0,
        group_id: 0,
        startDate: Moment(new Date()).format('YYYY-MM-DD'),
        endDate: Moment(new Date()).format('YYYY-MM-DD'),
      }
      : JSON.parse(CookieJs.get("REPORT_RANGE_PAGE") || "");
  const [pageSize, setPageSize] = useState<number>(10);
  const [keyword] = useState<any>({
    keyword: "",
  });
  const [options, setOptions] = useState<any>(reportCache);
  const getBranches = useQuery(
    ["branch", keyword],
    ({ queryKey }) => {
      return branch.getAllBranch({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  const groups = useQuery(
    ["groups"],
    () => {
      return group.getAllBranchGroup({ keyword: "" });
    },
    {
      ...queryDefault,
    }
  );

  const getReport = useQuery(
    ["reportRange", options],
    ({ queryKey }) => {
      return report.getReportRange({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  const form = useForm({
    defaultValues: reportCache,
  });

  useEffect(() => {
    form.setValue("startDate", Moment(valueFrom?.format('YYYY-MM-DD')).format('YYYY-MM-DD'));
    form.setValue("endDate", Moment(valueTo?.format('YYYY-MM-DD')).format('YYYY-MM-DD'));
    CookieJs.set(
      "REPORT_RANGE_PAGE",
      JSON.stringify({
        branchid: form.getValues().branchid,
        group_id: form.getValues().group_id,
        startDate: form.getValues().startDate,
        endDate: form.getValues().endDate,
      })
    );
    setOptions({ ...options, startDate: form.getValues().startDate, endDate: form.getValues().endDate });
  }, [valueFrom, valueTo, form]);

  const downloadMonthlySales = useMutation(
    "download-monthly-sales",
    sales.downloadMonthlySalesRange
  );
  const downloadMonthlySalesGroup = useMutation(
    "download-monthly-sales-group",
    sales.downloadMonthlySalesGroupRange
  );

  const onHandleDownloadMonthlySales = (params: any) =>
    downloadMonthlySales.mutate(params);
  const onHandleDownloadMonthlySalesGroup = (params: any) =>
    downloadMonthlySalesGroup.mutate(params);

  let thisYear = new Date().getFullYear();
  let allYears: number[] = [];
  for (let x = 0; x <= 20; x++) {
    allYears.push(thisYear - x);
  }

  const [filter, setFilter] = useState("");

  return (
    <MainLayout>
      <Helmet>
        <title>REPORT (Range)</title>
      </Helmet>

      <Breadcrumb
        label="Report (Range)"
        breadcrumbs={[{ label: "Report Range", href: "/app/range-report" }]}
      />

      <ProtectedPermissionPage acceptPermissions={[1, 2, 3]}>
        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <FormControl sx={{ width: 300 }}>
              <FormLabel required>Branch</FormLabel>
              <Controller
                name="branchid"
                control={form.control}
                render={({ field }) => (
                  <Autocomplete
                    options={get(getBranches, "data.data", [])}
                    getOptionLabel={(option: any) => option.name}
                    onChange={(event, newValue) => {
                      form.setValue("branchid", newValue ? newValue.id : 0);
                      CookieJs.set(
                        "REPORT_RANGE_PAGE",
                        JSON.stringify({
                          branchid: Number(newValue ? newValue.id : 0),
                          group_id: form.getValues().group_id,
                          startDate: form.getValues().startDate,
                          endDate: form.getValues().endDate,
                        })
                      );
                      setOptions((prev: any) => ({
                        ...prev,
                        branchid: Number(newValue ? newValue.id : 0),
                      }));
                      getReport.refetch();
                    }}
                    filterOptions={(options, { inputValue }) =>
                      options.filter((option) =>
                        option.name
                          .toLowerCase()
                          .includes(inputValue.toLowerCase())
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Branch"
                        variant="outlined"
                        value={filter}
                        onChange={(e: any) => {
                          setFilter(e.target.value);
                          if (params.inputProps.onChange) {
                            params.inputProps.onChange(e); // Ensure the input value is managed correctly
                          }
                        }}
                      />
                    )}
                    disabled={form.getValues().id > 0}
                    noOptionsText="No branches available"
                  />
                )}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Group</FormLabel>
              <Controller
                name="group_id"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <Select
                    id="group_id"
                    value={form.getValues().group_id}
                    onChange={(event) => {
                      form.setValue("group_id", Number(event.target.value));
                      CookieJs.set(
                        "REPORT_RANGE_PAGE",
                        JSON.stringify({
                          branchid: form.getValues().branchid,
                          group_id: Number(event.target.value),
                          startDate: form.getValues().startDate,
                          endDate: form.getValues().endDate,
                        })
                      );
                      setOptions((prev: any) => ({
                        ...prev,
                        group_id: Number(event.target.value),
                      }));
                      getReport.refetch();
                    }}
                    defaultValue={
                      form.getValues().group_id ? form.getValues().group_id : 0
                    }
                  >
                    <MenuItem key={0} value={0}>
                      Select Group
                    </MenuItem>
                    {get(groups, "data.data", []).map(
                      (data: any, index: number) => (
                        <MenuItem key={index} value={data.id}>
                          {data.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                )}
              />
            </FormControl>

            <FormControl margin="normal">
              <FormLabel>Start Date</FormLabel>
              <Controller
                name="date_from"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      views={["day", "month", "year"]}
                      value={valueFrom}
                      onChange={newValue => {
                        setValueFrom(newValue);
                      }}
                      renderInput={props => <TextField {...props} />}
                    />
                  </LocalizationProvider>
                )}
              />
            </FormControl>

            <FormControl margin="normal">
              <FormLabel>End Date</FormLabel>
              <Controller
                name="date_to"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      views={["day", "month", "year"]}
                      value={valueTo}
                      onChange={newValue => {
                        setValueTo(newValue);
                      }}
                      renderInput={props => <TextField {...props} />}
                    />
                  </LocalizationProvider>
                )}
              />
            </FormControl>
          </Stack>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              component={RouterLink}
              to="/app/report/create-report"
            >
              Add Report
            </Button>

            <LoadingButton
              variant="contained"
              onClick={form.handleSubmit(onHandleDownloadMonthlySales)}
              loading={downloadMonthlySales.isLoading}
            >
              Monthly Sales
            </LoadingButton>

            {options.group_id > 0 && (
              <LoadingButton
                variant="contained"
                onClick={form.handleSubmit(onHandleDownloadMonthlySalesGroup)}
                loading={downloadMonthlySalesGroup.isLoading}
              >
                Monthly Sales (Group)
              </LoadingButton>
            )}
          </Stack>

          <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
            <ProtectedPermissionPage acceptPermissions={[1, 2]}>
              <Stack direction="row" spacing={1}>
                <Button
                  variant="outlined"
                  component={RouterLink}
                  to={`/app/report/all`}
                >
                  All Report
                </Button>
              </Stack>
            </ProtectedPermissionPage>
          </Stack>
        </Box>

        <Box sx={{ mt: 2 }}>
          <DataGrid
            autoHeight
            loading={getReport.isLoading}
            getRowId={(record) => get(record, "id")}
            rows={get(getReport, "data.data", [])}
            rowCount={get(getReport, "data.data", []).length}
            page={options.page_number}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            disableSelectionOnClick
            onPageChange={(newPage) => {
              setOptions((prev: any) => ({ ...prev, page_number: newPage }));
            }}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            components={{
              NoRowsOverlay: CustomNoRowOverlay,
            }}
            columns={[
              {
                field: "name",
                headerName: "Branch",
                flex: 1,
                filterable: false,
                minWidth: 300,
                renderCell: (params) => {
                  return (
                    <Stack>
                      <Typography>{params.value}</Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "date",
                headerName: "Date",
                flex: 1,
                filterable: false,
                minWidth: 150,
                renderCell: (params) => {
                  return (
                    <Stack>
                      <Typography color="blue">
                        {moment(params.value).format("DD-MM-YYYY")}
                      </Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "quantity",
                headerName: "Quantity",
                flex: 1,
                filterable: false,
                minWidth: 150,
                renderCell: (params) => {
                  return (
                    <Stack>
                      <Typography color="blue">{params.value}</Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "price",
                headerName: "Price",
                flex: 1,
                filterable: false,
                minWidth: 150,
                renderCell: (params) => {
                  return (
                    <Stack>
                      <Typography color="blue">{params.value}</Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "actions",
                headerName: "Action",
                sortable: false,
                filterable: false,
                width: 200,
                renderCell: (params) => {
                  return (
                    <Stack direction="row" spacing={1}>
                      <Button
                        variant="outlined"
                        color="primary"
                        component={RouterLink}
                        to={`/app/report/edit/${get(params, "row.id")}`}
                      >
                        Edit
                      </Button>
                    </Stack>
                  );
                },
              },
            ]}
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
              },
            }}
          />
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
