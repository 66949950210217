import { Box, Theme } from "@mui/material";
import { Outlet } from "react-router-dom";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginRight: "auto",
    marginLeft: "auto",
    maxWidth: 'none',
  },
}));

export default function MinimalLayout() {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Outlet />
    </Box>
  );
}
